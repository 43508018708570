import React, { useState, useEffect } from "react"
import "../theme/type.scss"
import CustomFooter from "../components/CustomFooter"
import GdprCookies from "../components/GdprCookies"
import HeaderLayout from "../components/layout/HeaderLayout"
import "../theme/layout.scss"
import "../theme/david.scss"
import { HeadApi } from "../components/HeadApi"
import ModalSuscribe from "../components/layout/ModalSuscribre"
import { useLocation } from "@reach/router"

const sectionNames = {
  1: "Cartera Modelo de Análisis Técnico",
  2: "Cartera Modelo Fundamental",
  3: "Cambio de recomendaciones",
  4: "Emisoras de S&P500 mejor rankeadas por el consenso",
  5: "Selección de Emisoras “Favoritas” (Top picks) de análisis fundamental",
}
export const Head = ({ data }) => {
  const location = useLocation()
  const [pdfTitle, setPdfTitle] = useState("")


  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const idParam = params.get("title")

    if (idParam && sectionNames[idParam]) {
      setPdfTitle(sectionNames[idParam])
    }
  }, [location.search])

  return (
    <HeadApi
      title={pdfTitle}
      description="Visor PDF"
    />
  )
}


const VisorPDF = ({ data }) => {

  const location = useLocation()
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)
  const [pdfTitle, setPdfTitle] = useState<string | null>(null)


  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const pdfParam = params.get("pdf")
    const idParam = params.get("title")
    if (pdfParam) {
      setPdfUrl(pdfParam)
    }

    if (idParam && sectionNames[idParam]) {
      setPdfTitle(sectionNames[idParam])
    }
  }, [location.search])


  // if (!pdfUrl) {
  //   return <p>No se proporcionó una URL de PDF</p>;
  // }

  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }


  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          {pdfTitle && <h2 className="title--section">{pdfTitle}</h2>}
        </div>
        <div>
          {pdfUrl ? (
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              style={{ border: "none" }}
            />
          ) : (
            <p>Cargando...</p>
          )}
        </div>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

export default VisorPDF
